@tailwind base;
@tailwind components;
@tailwind utilities;

#didomi-host .didomi-components-radio__option {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

#didomi-host .didomi-consent-popup-title img {
  height: 20px !important;
}
